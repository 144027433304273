import { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import {
  getServiceListDa,
  listService,
} from "../../../../../api/userRegestration/CollectionForm";
import Spinner from "../../../../../component/Spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ListinputMain() {
  const [categories, setCategories] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const accessToken = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [promotebutton, setPromoteButton] = useState(true);
  const [getserviceId, setgetserviceId] = useState();

  //
  const handleButtonClick = () => {
    toast.success("you are now at promotion form!", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setPromoteButton(!promotebutton);
  };
  //
  const [formData, setFormData] = useState({
    ServiceCateogries: "",
    ServiceName: "",
    ServiceRate: "",
    ServiceDetails: "",
    img: "",
    Relevantskills: "",
    // shareBusinessCard: false,
    // followGuidelines: false,
  });
  //

  const handlesubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const service_List_Data = {
        serviceName: formData.ServiceName,
        mediaUrl: imageBase64,
        details: formData.ServiceDetails,
        rate: formData.ServiceRate,
        mediaType: "MEDIA",
        categorie: formData.ServiceCateogries,
        type: "SELLER",
      };

      const service_Data = await listService(service_List_Data);
      console.log('PRODUCT ID FROM THE PARENT COMPONENT ' , service_Data.result  );
      console.log('request  FROM THE PARENT COMPONENT ' , service_Data.result.saveRequest.serviceId  );

      // console.log("API Response:", service_Data);

      // let servideId;


      // console.log(
      //   "PRODUCT ID FROM THE PARENT COMPONENT ",
      //   service_Data.result.saveProduct.serviceId
      // );
      // console.log("id saved in the console storage ");



      // if (service_Data && service_Data.result && service_Data.result.saveRequest) {
      //   const serviceId = service_Data.result.saveRequest.serviceId;
      //   console.log("Service ID:", serviceId);
      //   setgetserviceId(serviceId);
      // } else {
      //   console.warn("Service ID not found in the response");
      // }

      console.log("service_list", service_Data);

      // toaster
      toast.success("service  hitted  successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setShowModal(true);
    } catch (error) {
      console.error("Error in :", error.message);
    }
    finally {
      setLoading(false); 
    }
  };
  //

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file); // Convert and store the image as Base64
    } else {
      setSelectedImage(null);
      setImageBase64(null); // Clear the Base64 data if no image is selected
    }
  };

  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };
  //
  useEffect(() => {
    getServiceListDa(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  //
  useEffect(() => {
    if (getserviceId) {
      console.log("Product ID from state:", getserviceId);
    }
  }, [getserviceId]);
  return (
    <>
      <div className="form">
        <form onSubmit={handlesubmit}>
          <div className="flex flex-row lg:gap-[9rem] mt-[3rem] mb-[2rem]">
            {/* 1 */}
            <div className="form-input flex flex-col  w-[47%]">
              <h1 className="font-all font-semibold text-center">
                Sell Services
              </h1>
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Service Cateogries
                </label>
                <select
                  name="ServiceCateogries"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.ServiceCateogries}
                  onChange={handleInputChange}
                >
                  <option value="" className="p-2" disabled>
                    Select Category
                  </option>
                  {categories.map((category) => (
                    <option
                      key={category._id}
                      value={category.name}
                      className="bg-white  transition-colors duration-300 p-2"
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>{" "}
              {/* 2  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Service Name
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="ServiceName"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  // aria-autocomplete="list"
                  value={formData.ServiceName}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Service Rate
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="ServiceRate"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  // aria-autocomplete="list"
                  value={formData.ServiceRate}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
              {/* 5  */}
              {/* text area  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                <label
                  htmlFor="input"
                  className="text text-sm text-black w-[7rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Service Details
                </label>
                <textarea
                  placeholder="Write here..."
                  name="ServiceDetails"
                  rows="4"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px] "
                  value={formData.ServiceDetails}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                <label
                  htmlFor="input"
                  className="text text-sm text-black w-[10rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Relevant skills/Keywords
                </label>
                <textarea
                  placeholder="Write here..."
                  name="Relevantskills"
                  rows="7"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.Relevantskills}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              {/*  */}
            </div>

            {/* 2 */}

            <div className="inline-flex flex-col mt-[4rem] lg:gap-[3rem]">
              {/*  */}
              <div className="flex items-center justify-center w-full">
                <label
                  style={{
                    display: selectedImage ? "none" : "block",
                  }}
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 shark:text-gray-400">
                      SVG, PNG, JPG, or GIF (MAX. 800x400px)
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={handleImageChange}
                  />
                </label>
                {selectedImage && (
                  <div className="flex items-center  w-auto p-[10px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
                    <img
                      className="w-full h-44 mx-auto "
                      src={selectedImage}
                      alt="Selected"
                    />
                  </div>
                )}
              </div>

              {/*  */}
              <div className="/">
                <div class="flex items-center ">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="link-checkbox"
                    class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
                  >
                    I agree to share my Bussiness card with Supplier .
                  </label>
                </div>

                <div class="flex items-center mt-3">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="link-checkbox"
                    class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
                  >
                    I agree to follow buyer posting guideline .
                  </label>
                </div>
              </div>

              {/* buttin action */}

              <div class="button-action flex flex-col items-center mt-6">
                <button
                  type="submit"
                  class=" bg-[#CB2229] flex flex-row justify-center items-center gap-2 text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                  disabled={loading}
                >
                  {loading && <Spinner />} Share Inputs
                </button>
                {/* <Link to="/create_module_promotion" > */}
                <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                  Create a Campaign
                </button>
                {/* </Link> */}
              </div>
            </div>
            {/*  */}
          </div>
        </form>
      </div>


{  promotebutton ? <></> :

<>



</>
}


    </>
  );
}
