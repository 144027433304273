import { useEffect, useState } from "react";
import {
  job_categories,
  getAllProductCategories,
  CreateJOb
} from "../../../../../api/userRegestration/CollectionForm";

export default function PostInputMain() {
  const [categories, setCategories] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const accessToken = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    cateogries: "",
    professioncategories: "",
    salaryRange: "",
    exprienceRequierd: "",
    jobescription: "",
    releaventSkills: "",

  });
// 
const handlesubmit = async (event) => {
  event.preventDefault();
  try {
    const Create_JOB_List_Data = {
    
      sector: formData.cateogries,
      profession: formData.professioncategories,
      mediaUrl: imageBase64,
      description: formData.jobescription,
      salary: formData.salaryRange,
      mediaType: "MEDIA",
      experience: formData.exprienceRequierd,
      type: "SELLER",
      skills: formData.releaventSkills,
    };
    const Job_data = await CreateJOb(Create_JOB_List_Data);
    console.log("job data", Job_data);
  } catch (error) {

    console.error("Error in :", error.message);

  }
};

const handleImageChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl);
    convertImageToBase64(file); // Convert and store the image as Base64
  } else {
    setSelectedImage(null);
    setImageBase64(null); // Clear the Base64 data if no image is selected
  }
};

const convertImageToBase64 = (file) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const base64Data = e.target.result;
    setImageBase64(base64Data);
  };
  reader.readAsDataURL(file);
};

const handleInputChange = (event) => {
  const { name, value, type, checked } = event.target;
  const newValue = type === "checkbox" ? checked : value;
  setFormData({ ...formData, [name]: newValue });
};


  //
  useEffect(() => {
    job_categories(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  return (
    <>
   <div className="form">
        <form onSubmit={handlesubmit}>
          <div className="flex flex-row lg:gap-[9rem] mt-[3rem] mb-[2rem]">
            {/* 1 */}
            <div className="form-input flex flex-col  w-[47%]">
              <h1 className="font-all font-semibold text-center">
              Post a Job
              </h1>
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Sector Categories
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="cateogries"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  aria-autocomplete="list"
                  value={formData.cateogries}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/* 2  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[10rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Profession Categories
                </label>
               
<select
                  name="ProefessionCategories"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.ProefessionCategories}
                  onChange={handleInputChange}
                >
                  <option value="" className="p-2" disabled>
                    Select Category
                  </option>
                  {categories.map((category) => (
                    <option
                      key={category._id}
                      value={category.name}
                      className="bg-white  transition-colors duration-300 p-2"
                    >
                      {category.name}
                    </option>
                  ))}
                </select>

                {/*  */}
              </div>{" "}
              {/* 3 */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
Salary Range
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="salaryRange"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  aria-autocomplete="list"
                  value={formData.salaryRange}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/* 4  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[9rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Expirienced Requierd
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="exprienceRequierd"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  aria-autocomplete="list"
                  value={formData.exprienceRequierd}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/* 5  */}
              {/* text area  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                <label
                  htmlFor="input"
                  className="text text-sm text-black w-[8rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Job Description
                </label>
                <textarea
                  placeholder="Write here..."
                  name="jobescription"
                  rows="3"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  aria-autocomplete="list"
                  value={formData.jobescription}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                <label
                  htmlFor="input"
                  className="text text-sm text-black w-[8rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Relavent Skills
                </label>
                <textarea
                  placeholder="Write here..."
                  name="releaventSkills"
                  rows="5"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.releaventSkills}
                  onChange={handleInputChange}
                  aria-autocomplete="list"
                ></textarea>
              </div>
              <div className="add-attachment flex flex-row items-center gap-3 ml-[3rem] mt-4">
                <span className="font-all text-[35px]">+</span>{" "}
                <span className="font-all">add attachment</span>
              </div>
            </div>

            {/* 2 */}
            <div className="flex flex-col mt-8">
            <div className="flex items-center justify-center w-full">
  <label
  style={{
    display: selectedImage ? 'none' : 'block'}}
  
    htmlFor="dropzone-file"
    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
  >
    <div className="flex flex-col items-center justify-center pt-5 pb-6">
      <svg
        className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
        />
      </svg>
      <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
        <span className="font-semibold">Click to upload</span> or drag and drop
      </p>
      <p className="text-xs text-gray-500 shark:text-gray-400">
        SVG, PNG, JPG, or GIF (MAX. 800x400px)
      </p>
    </div>
    <input
      id="dropzone-file"
      type="file"
      className="hidden"
      onChange={handleImageChange}
    />
  </label>
  {selectedImage && (
    <div className="flex items-center  w-auto p-[10px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
   
      <img
      className="w-full h-44 mx-auto "
        src={selectedImage}
        alt="Selected"
      
      />
    </div>
  )}
</div>
            <div class="flex items-center">
                <input
                  id="link-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="link-checkbox"
                  class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
                >
                  I agree to share my Bussiness card with Supplier
                  
                  .
                </label>
              </div>

              <div class="flex items-center mt-3">
                <input
                  id="link-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="link-checkbox"
                  class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
                >
                  I agree to follow buyer posting guideline
               
                  .
                </label>
              </div>
            
              {/* buttin action */}

              <div class="button-action flex flex-col items-center mt-6">
                <button typeof="submit" type="submit" class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
                  Submit Inputs{" "}
                </button>
                <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                  Create a Campaign
                </button>
              </div>
            </div>
            {/*  */}
          </div>
        </form>
      </div>
    </>
  )
}
