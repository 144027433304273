import React, { useEffect, useState } from "react";
import {ListOtherProjects} from "../../../../../api/userRegestration/CollectionForm"

import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { Rating } from "flowbite-react";
import { useNavigate } from "react-router-dom";
function OtherProjects() {
  const carouselData = [
    {
      name: "Apple",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
    {
      name: "samsung",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
    {
      name: "lenovo",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
    {
      name: "mi",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
    {
      name: "poco",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
    {
      name: "realme",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
    {
      name: "realme",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
    {
      name: "realme",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
    {
      name: "realme",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
    {
      name: "realme",
     image:"https://c4.wallpaperflare.com/wallpaper/450/147/796/nike-just-do-it-slogan-wallpaper-thumb.jpg",
    },
  ];
  const scrollLeft = () => {
    document.getElementById("content").scrollLeft -= 400;
  };
  const scrollRight = () => {
    document.getElementById("content").scrollLeft += 400;
  };

  const [showIcon1, setShowIcon1] = useState(true);

  const toggleIcon = () => {
    setShowIcon1(!showIcon1);
  };
  const accessToken = localStorage.getItem("token");

// 
const [Product, setProduct] = useState([]);

  useEffect(() => {
    ListOtherProjects(accessToken)
      .then((result) => {
        setProduct(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  const navigate = useNavigate();

  const handleroute = (jobId) => {
    navigate(`/marketTrend/Other_Project/Project_detail/${jobId}`);
  };

// 
  return (
 <>
      <div className="relative">
        <h1 className="text-start font-all font-extrabold text-[#263238] lg:text-[19px]">
         Other Projects
        </h1>

        <div className="absolute right-0 lg:top-[3.2rem] z-10 xs:top-[20px]">
          <button
            onClick={scrollLeft}
            className="relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:right-[67.5rem] carousel-bg"
          >
            <FiChevronLeft className="relative lg:right-[1px] text-black" />
          </button>
          <button
            onClick={scrollRight}
            className=" relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:left-[65px]"
          >
            <FiChevronRight className="relative text-black" />
          </button>
        </div>
        <div
          id="content"
          className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1"
        >
          {Product.map((item) => (
            <div
              className="cursor-pointer carousel-card lg:ml-[-18px]"
              key={item._id}
            >
              <div className="p-4 lg:w-[246px] xs:w-[14rem] drop-shadow-xl">
                <div className="bg-white p-[5px] absolute z-10 rounded-full right-[31px] top-[22px] cursor-pointer">
                  {showIcon1 ? (
                    <AiOutlineHeart onClick={toggleIcon} />
                  ) : (
                    <AiFillHeart
                      className="text-red-400"
                      onClick={toggleIcon}
                    />
                  )}
                </div>

                <div className=" lg:w-[202px] h-max shadow-inner  rounded-lg overflow-hidden text-center relative intrest-card bg-[#ffff] h-[137px]">
                  <img
                    src={item.mediaUrl}
                    className="lg:h-[142px] l:w-[242px] w-full"
                    alt=""
                  />

                  {/* <div className="flex flex-row items-center justify-center gap-[15px]">
                 

                    <Rating className="relative top-[3px]">
                      <Rating.Star className="text-yellow-300 text-[10px]" />
                      <Rating.Star className="text-yellow-300 text-[10px]" />
                      <Rating.Star />
                      <p className="ml-2 font-medium text-gray-500 dark:text-gray-400 text-[10px]">
                        4.95 /5
                      </p>
                    </Rating>
                  </div> */}

                  <div className="px-4 pt-2">
                    <div>
                      <h5 className="text-black font-all font-bold  text-start whitespace-nowrap">
                        {item.projectName}
                      </h5>
                    </div>
                  </div>

                  <p className="description font-light italic text-gray-500 text-start px-2 pt-2">
                    {item.details.split(" ").slice(0, 6).join(" ")}....
                  </p>

                  <div className="flex justify-center mt-[6px]">
                    <div className="flex flex-row-reverse items-center  gap-[38px]">
                      <button
                        onClick={() => handleroute(item._id)}
                        className="mb-[5px] flex select-none items-center mb-3px text-center gap-2 rounded-lg py-2 px-2 text-center align-middle font-sans text-xs font-bold uppercase text-gray-500 transition-all bg-gray-500/10  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                      >
                        view
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                          />
                        </svg>
                      </button>

                      <p className="font-all font-light text-red-500">
                        {" "}
                        {item.budget} omr
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center justify-center gap-[3rem]">
                    {/* <div className="item-b item-end">
                      <span style={{ color: "red" }}>OMR </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
 </>
  )
}

export default OtherProjects ;
