// api.js
import axios from "axios";
import * as ENDPOINTS from "../URlcollection";

export const product_promotion = async (promotedvalue) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createProductPromotion",
      promotedvalue,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
// 
export const service_promotion = async (promotedvalue) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createServicePromotion",
      promotedvalue,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
// 
export const job_promotion = async (promotedvalue) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createJobPromotion",
      promotedvalue,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
// 
export const post_promotion = async (promotedvalue) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createPostPromotion",
      promotedvalue,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};