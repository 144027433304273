import React from "react";
// css
import "./assets/css/Animation.css";
// router dom
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
// Layout
import Mainlayout from "./Layout/Mainlayout";
// pages
import Homestock from "./pages/home/Homestock";
import Chat from "./pages/Chats/Chat";
import Termstock from "./pages/Terms&Service/Termstock";
import Privacypolicymain from "./pages/Privacypolicy/Privacypolicymain";
import Licensestock from "./pages/License/Licensestock";
import Helpmain from "./pages/Helpcenter/Helpmain";
import Referalstock from "./pages/Referal/Referalstock";
import Contactstock from "./pages/Contact/Contactstock";
import Walletstock from "./pages/Wallet/Walletstock";
import Addmoney from "./pages/Wallet/Transferfund/Addmoney";
import TransferMain from "./pages/Wallet/Rewards/TransferMain";
import WalletsMoney from "./pages/Wallet/Transferfund/WalletsMoney";
import WithdarwWallet from "./pages/Wallet/Withdrawfunds/WithdarwWallet";
import Withdrawtransfersuccess from "./pages/Wallet/Withdrawfunds/Withdrawtransfersuccess";
import ProductMainComponent from "./pages/home/ServicesByINO/FindProduct/ProductMainComponent";
import ExploremainComponent from "./pages/home/ServicesByINO/ExploreService/ExploremainComponent";
import JobMain from "./pages/home/ServicesByINO/FinDjobs/JobMain";
import AddProjectMain from "./pages/home/ServicesByINO/AddProject/AddProjectMain";
import ListMain from "./pages/home/ServicesByINO/ListService/ListMain";
import AuthLayout from "./Layout/AuthLayout";
import AuthMain from "./pages/Auth/AuthMain";
import VerifyPhone from "./pages/Auth/VerifyPhone";
import VerifyPhoneOtp from "./pages/Auth/VerifyPhoneOtp";
// import Registration from "./pages/Auth/Registration";
import Intrest from "./pages/Auth/Intrest";
import PostjObMain from "./pages/home/ServicesByINO/PostjOB/PostjObMain";
import PostProjectMain from "./pages/home/ServicesByINO/PostProject/PostProjectMain";
import HomePSliderMain from "./pages/home/HomePSliderPage/HomePSliderMain";
import InquiriesAllSent from "./pages/home/ServicesByINO/FindProduct/Profile/Inquiries/InquiriesAllSent";
import InquiriesAllReceived from "./pages/home/ServicesByINO/FindProduct/Profile/Inquiries/InquiriesAllReceived";
import AllAdwordsMain from "./pages/home/ServicesByINO/FindProduct/Profile/Adwords/AllAdwordsMain";
import AllRequested from "./pages/home/ServicesByINO/FindProduct/Profile/BussinessCard&Contactinformation/AllRequested";
import AllReceived from "./pages/home/ServicesByINO/FindProduct/Profile/BussinessCard&Contactinformation/AllReceived";
import CreateBussinessCard from "./pages/home/ServicesByINO/FindProduct/Profile/BussinessCard&Contactinformation/CreateBussinessCard";
import ViewCard from "./pages/home/ServicesByINO/FindProduct/Profile/BussinessCard&Contactinformation/ViewCard";
// import ViewAllInput from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/ViewAllInput";
// import CreateNewCampaign from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/CreateNewCampaign";
import AllProductHistorymain from "./pages/home/ServicesByINO/FindProduct/Profile/AllProductHistory/AllProductHistorymain";
import CateogrizeProductMain from "./pages/home/ServicesByINO/FindProduct/Profile/CateogrizeProductMarketTrend/CateogrizeProductMain";
import CorprateService from "./pages/home/ServicesByINO/FindProduct/Profile/CopraeService/CorprateService";
import ResellerLayout from "./Layout/ResellerLayout";
import ResellerDashboardMain from "./pages/home/BecomeReseller/REsellerScreens/ResellerDashboardMain";
import UserDetaiLTable from "./pages/home/BecomeReseller/REsellerScreens/UserDetaiLTable";
// import ResellerDashboard from "./Layout/ResellerDashboard";
import ResellerForm from "./pages/home/BecomeReseller/ResellerForms/ResellerForm";
import ResellerVerifyPhone from "./pages/home/BecomeReseller/ResellerForms/ResellerVerifyPhone";
// importing function to visible reseller dashboard
// import { handleButtonClick } from "../src/Layout/Mainlayout";
// import Profile from "./pages/Profile/Profile";
import WelcomeAuth from "./pages/Auth/WelcomeAuth";
import Viewallregestration from "./pages/home/ServicesByINO/AddProject/AddProfile/ViewAllRegestration/Viewallregestration";
import Userprofile from "./pages/home/userProfile/Userprofile";
import CampaignMainPage from "./pages/home/userProfile/CampaignPage/CampaignMainPage";
import InquiriesSentChatScreen from "./pages/home/ServicesByINO/FindProduct/Profile/Inquiries/InquiriesSentChatScreen";
import RegisterUserForm from "./pages/Auth/RegisterUserForm";
// import TestOtp from "./test/TestOtp";
// import TeestRegister from "./test/TeestRegister";
import CreatePost from "./pages/home/ServicesByINO/CreatePost";
import Response from "./test/Response";
import ProductList from "./test/ProductList";
import StoreRegestrationForm from "./pages/home/HomePageMainComponent/TargerserviceButton/StoreRegestrationForm";
// import PallProductPart from "./pages/home/HomePSliderPage/HomePAll/PallProductPart";
// import PallMain from "./pages/home/HomePSliderPage/HomePAll/PallMain";
import Productpage from "./pages/home/HomePSliderPage/ProductPage/Productpage";
import AddProjectProductDetail from "./pages/home/ServicesByINO/AddProject/AddProjectAll/AddProjectProductDetail";
import ExploreServiceDetailView from "./pages/home/ServicesByINO/ExploreService/ExploreAll/ExploreServiceDetailView";
import ServiceListDetails from "./pages/home/ServicesByINO/ListService/Listall/ServiceListDetails";
import FindJobDetails from "./pages/home/ServicesByINO/FinDjobs/JobAll/FindJobDetails";
import PostJobDetail from "./pages/home/ServicesByINO/PostjOB/PostjObAll/PostJobDetail";
import MarketTrendMain from "./pages/home/MarketTrends/GonvernmentMandiProject/MarketTrendMain";
import GovProjectDetail from "./pages/home/MarketTrends/GonvernmentMandiProject/GovProjects/GovProjectDetail";
import BecomeAReseller from "./pages/home/BecomeReseller/REsellerScreens/BecomeAReseller";
import OtherProjectDetail from "./pages/home/MarketTrends/GonvernmentMandiProject/GovProjects/OtherProjectDetail";
import InputTabs from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/InputTabs";
import ProductDetail from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/Detail/ProductDetail";
import JobDetail from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/Detail/JobDetail";
import ServiceDetail from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/Detail/ServiceDetail";
import ProjectDetail from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/Detail/ProjectDetail";
import PostDetail from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/Detail/PostDetail";
import ChangeNumber from "./pages/Auth/ChangeNumber";
import PromotionHeader from "./pages/PromotionForm/PromotionHeader";

// paths
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/response" element={<Response />} />
      <Route path="/port" element={<ProductList />} />

      <Route path="/" element={<Mainlayout />}>
        <Route index element={<Homestock />} />

<Route path="/update_phone_number"  element={<ChangeNumber/>}    />
<Route path="/create_module_promotion" element={<PromotionHeader/>}/>


        <Route path="userProfile" element={<Userprofile />} />

        <Route path="ResellerDashboard" element={<ResellerDashboardMain />} />
        <Route
          path="/Resellerdashboard-userdetail"
          element={<UserDetaiLTable />}
        />

        <Route
          path="/findproducts/inquiriesSent/user"
          element={<InquiriesSentChatScreen />}
        />

        <Route path="findproducts" element={<ProductMainComponent />} />
        <Route
          path="/findproducts/products_for_you_/product_detail_/:productId"
          element={<Productpage />}
        />
        <Route
          path="/findproducts/inquiriesSent"
          element={<InquiriesAllSent />}
        />
        <Route
          path="/findproduct/viewregestration"
          element={<Viewallregestration />}
        />

        <Route
          path="/findproduct/ProductPage/:storeId"
          element={<HomePSliderMain />}
        />

        <Route
          path="/findproducts/inquiriesReceived"
          element={<InquiriesAllReceived />}
        />
        <Route path="/findproducts/adwords" element={<AllAdwordsMain />} />
        <Route path="/findproducts/requestedCard" element={<AllRequested />} />
        <Route
          path="/findproduct/bussinessrequestReceived"
          element={<AllReceived />}
        />
        <Route
          path="/findproduct/bussinessCreatecard"
          element={<CreateBussinessCard />}
        />
<Route path="/findproduct/businessviewCard/:businessCardId" element={<ViewCard />} />

        <Route path="/findproduct/viewallinput" element={<InputTabs />} />
      {/* input detail pages */}
   {/* input listing details  */}
   {/* 01 */}
      <Route
          path="/findproducts/listing/Detail/:productId"
          element={<ProductDetail />}
        />

{/* 02  */}
<Route path="/findproducts/listing/jobDetail/:jobId" element={<JobDetail />} />
{/* 03  */}
<Route path="/findproducts/listing/serviceDetail/:serviceId" element = {<ServiceDetail/>}/>
{/* 04  */}
<Route path="/findProducts/listing/ProjectDetail/:projectId" element = {<ProjectDetail/>}  />
{/* 05  */}
<Route path="/findProducts/listing/PostDetail/:postId"  element={<PostDetail/>} />
      {/*  */}


      
      
      
        <Route
          path="/findproduct/createnewcampaign"
          element={<CampaignMainPage />}
        />
        <Route
          path="/findproduct/posthistory"
          element={<AllProductHistorymain />}
        />

        <Route
          path="/findproduct/cateogrizeProduct"
          element={<CateogrizeProductMain />}
        />
        <Route
          path="/findproduct/corporateService"
          element={<CorprateService />}
        />
        {/* profile page routing end here  */}
        <Route />
        <Route path="exploreService" element={<ExploremainComponent />} />
        <Route path="findjobs" element={<JobMain />} />
        <Route path="addProject" element={<AddProjectMain />} />
        <Route path="ListProject" element={<ListMain />} />
        <Route path="PostJob" element={<PostjObMain />} />

        <Route
  path="/addProject/products_for_you_/product_detail_/:productId"
  element={<AddProjectProductDetail />}
/>
        <Route
          path="/exploreService/Services_Buyer/Service_Details/:serviceId"
          element={<ExploreServiceDetailView />}
        />
        <Route path="/ListProject/Services_Seller/Service_Details/:serviceId" element={<ServiceListDetails/>} />

<Route path="/findjobs/Job_Buyer/Job_details/:jobId" element={<FindJobDetails/>} />

<Route path="/PostJob/Job_Buyer/Job_details/:jobId" element={<PostJobDetail/>} />

        <Route path="/addProject/creatpost" element={<CreatePost />} />
        <Route path="PostProject" element={<PostProjectMain />} />
        {/* lorum  */}
        {/*  */}
        {/* REGISTER YOUR STORE  */}
        <Route path="register_your_store" element={<StoreRegestrationForm />} />
        {/*  */}
        <Route path="chat" element={<Chat />} />
        <Route path="referal" element={<Referalstock />} />
        <Route path="contact-us" element={<Contactstock />} />
        <Route path="TermsandService" element={<Termstock />} />
        <Route path="Privacy&Policy" element={<Privacypolicymain />} />
        <Route path="wallet" element={<Walletstock />} />
        <Route path="/wallet/walletrewards" element={<TransferMain />} />
        <Route path="/wallet/addmoney" element={<Addmoney />} />
        <Route path="License" element={<Licensestock />} />
        <Route path="Helpcenter" element={<Helpmain />} />
        <Route path="/wallet/userwallet/:serviceId" element={<WalletsMoney />} />
        <Route path="/wallet/withdraw" element={<WithdarwWallet />} />
        <Route
          path="/wallet/withdrawlsuccess/:serviceId"
          element={<Withdrawtransfersuccess />}
        />

        {/* market */}
<Route path="marketTrend" element={<MarketTrendMain/>}/>
<Route path="/marketTrend/Gov_Project/Project_details/:jobId" element={<GovProjectDetail/>} />
<Route path="/marketTrend/Other_Project/Project_detail/:jobId" element={<OtherProjectDetail/>}   />
      </Route>
      {/* authentication or regextration & login pages  */}
      <Route path="auth" element={<AuthLayout />}>
        <Route path="/auth/welcome" element={<WelcomeAuth />} />

        <Route path="/auth/to" element={<AuthMain />} />
        <Route path="/auth/verification" element={<VerifyPhone />} />
        <Route
          path="/auth/verifyphone/:mobileNumber"
          element={<VerifyPhoneOtp />}
        />

        <Route path="/auth/registerUser" element={<RegisterUserForm />} />
        <Route path="/auth/intrest" element={<Intrest />} />
      </Route>

      {/* resleer page routing end  */}
      {/* reseller from  */}
      <Route path="register" element={<ResellerLayout />}>
        <Route path="/register/resellerForm" element={<ResellerForm />} />
        <Route
          path="/register/resellerVerification/:email"
          element={<ResellerVerifyPhone />}
        />
        <Route path="/register/reseller/Become_Reseller" element={<BecomeAReseller/>} />
      </Route>
    </Route>
  )
);

function Routes() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default Routes;
